<template v-if="loggedIn">
  <v-container fluid>
    <v-row dense>
      <v-col cols="6" v-if="hasRole('ROLE_READ_SEMINAR') && userData.activeTenant">
        <v-card color="mainAccent lighten-1" dark>
          <v-card-title class="headline">Seminare</v-card-title>
          <v-card-subtitle>
            <span v-if="speakerCount">Insgesamt {{ seminarCount }} Seminare</span>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn :to="{ name: 'SeminarList' }" text>Verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="hasRole('ROLE_READ_TOPIC') && userData.activeTenant">
        <v-card color="mainAccent lighten-1" dark>
          <v-card-title class="headline">Themen</v-card-title>
          <v-card-subtitle>
            <span v-if="topicCount">Insgesamt {{ topicCount }} Themen</span>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn :to="{ name: 'TopicList' }" text>Verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="hasRole('ROLE_ADMIN')">
        <v-card color="mainAccent lighten-1" dark>
          <v-card-title class="headline">Kunden</v-card-title>
          <v-card-subtitle>
            <span v-if="customerCount">Insgesamt {{ customerCount }} Kunden</span>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn :to="{ name: 'CustomerList' }" text>Verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="hasRole('ROLE_ADMIN')">
        <v-card color="mainAccent lighten-1" dark>
          <v-card-title class="headline">Referenten</v-card-title>
          <v-card-subtitle>
            <span v-if="speakerCount">Aktuell sind {{ speakerCount }} Referenten vorhanden</span>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn :to="{ name: 'SpeakerList' }" text>Verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="6" v-if="hasRole('ROLE_ADMIN')">
        <v-card color="mainAccent lighten-1" dark>
          <v-card-title class="headline">Aufgaben</v-card-title>
          <v-card-subtitle>
            <span v-if="ownIssueCount != null">Aktuell sind Ihnen {{ ownIssueCount }} Aufgabe(n) zugewiesen</span>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn :to="{ name: 'IssueList' }" text>Verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6" v-if="hasRole('ROLE_READ_VENUE')">
        <v-card color="mainAccent lighten-1" dark>
          <v-card-title class="headline">Veranstaltungsorte</v-card-title>
          <v-card-subtitle>
            <span v-if="venueCount">{{ venueCount }} Veranstaltungsorte vorhanden</span>
          </v-card-subtitle>
          <v-card-actions>
            <v-btn :to="{ name: 'VenueList' }" text>Verwalten</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {mapFields} from "vuex-map-fields";

export default {
  name: 'Dashboard',
  data: () => ({}),
  computed: {
    ...mapGetters({
      speakerItems: 'speaker/list',
      issueItems: 'issue/list',
    }),
    ...mapFields('speaker', {
      speakerCount: 'totalItems',
    }),
    ...mapFields('seminar', {
      seminarCount: 'totalItems',
    }),
    ...mapFields('issue', {
      issueCount: 'totalItems',
    }),
    ...mapFields('venue', {
      venueCount: 'totalItems',
    }),
    ...mapFields('topic', {
      topicCount: 'totalItems',
    }),
    ...mapFields('customer', {
      customerCount: 'totalItems',
    }),
    loggedIn: function () {
      return this.isLoggedIn();
    },
    ownIssueCount() {
      let ownIssues = this.issueItems.filter(((item) => {
        return (item.assignedTo && item.assignedTo === this.getUserIri());
      }))
      return ownIssues.length;
    }
  }
};
</script>
